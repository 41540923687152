import React, { Component } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import Order from "./components/order.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";
import BoardDev from "./components/board-dev.component";
import BoardSeller from "./components/board-seller.component";
import Fund from "./components/fund.component";

// import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
import AppLayout from "./layout";

import {
  UserOutlined,
  SettingOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  MoneyCollectOutlined,
  CrownOutlined,
  CreditCardOutlined
} from '@ant-design/icons';

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      showDevBoard: false,
      showSellerBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("Mod"),
        showAdminBoard: user.roles.includes("Admin"),
        showDevBoard: user.roles.includes("Dev"),
        showSellerBoard: user.roles.includes("Seller")
      });
    }
    
    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      showDevBoard: false,
      showSellerBoard: false,
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard, showDevBoard, showSellerBoard } = this.state;

    const sidebarItems = [
      {
        key: "/home",
        label: <Link to="/home">Trang Chủ</Link>,
        icon: <HomeOutlined />,
      },
      showModeratorBoard && {
        key: "/mod",
        label: <Link to="/mod">Quản Lý</Link>,
        icon: <SettingOutlined />,
      },
      showAdminBoard && {
        key: "/admin",
        label: <Link to="/admin">Admin</Link>,
        icon: <CrownOutlined />,
      },
      showDevBoard && {
        key: "/dev",
        label: <Link to="/dev">Quản Trị Viên</Link>,
        icon: <SettingOutlined />,
      },
      showSellerBoard && {
        key: "/seller",
        label: <Link to="/seller">Bán Hàng</Link>,
        icon: <MoneyCollectOutlined />,
      },
      currentUser && {
        key: "/profile",
        label: <Link to="/profile">Người Dùng</Link>,
        icon: <UserOutlined />
      },
      currentUser && {
        key: "/order",
        label: <Link to="/order">Đơn Hàng</Link>,
        icon: <ShoppingCartOutlined />
      },
      currentUser && {
        key: "/fund",
        label: <Link to="/fund">Nạp Tiền</Link>,
        icon: <CreditCardOutlined />
      },
      !currentUser && {
        key: "/login",
        label: <Link to="/login">Đăng Nhập</Link>,
      },
      !currentUser && {
        key: "/register",
        label: <Link to="/register">Đăng Ký</Link>,
      },
    ].filter(Boolean);

    return (
      <AppLayout sidebarItems={sidebarItems}>
        <div className="container mt-3">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/order" element={<Order />} />
            <Route path="/mod" element={<BoardModerator />} />
            <Route path="/admin" element={<BoardAdmin />} />
            <Route path="/dev" element={<BoardDev />} />
            <Route path="/seller" element={<BoardSeller />} />
            <Route path="/fund" element={<Fund />} />
          </Routes>
        </div>
      </AppLayout>
    );
  }
}

export default App;
