import React from "react";
import { Layout, Menu } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthService from "../services/auth.service";
const { Header, Sider, Content } = Layout;



const AppLayout = ({ children, sidebarItems }) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const selectedKey = location.pathname;
  const currentUser = AuthService.getCurrentUser();
  const logOut = () => {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      showDevBoard: false,
      currentUser: undefined,
    });
  }
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "64px" }}>
          <Link to="/">
            <img
              src={collapsed ? "./logo.png" : "./long_logo.png"}
              alt="Bú Kèo Để Thoát Nghèo"
              style={{
                width: "100%",
                height: "100%",
                ...(collapsed && { maxWidth: "40px", maxHeight: "40px" }),
              }}
            />
          </Link>
        </div>
        <Menu theme="dark" mode="inline" selectedKeys={[selectedKey]} items={sidebarItems} />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: "#fff" }}>
          {collapsed ? (
            <MenuUnfoldOutlined
              style={{ marginLeft: 16, cursor: "pointer" }}
              onClick={() => setCollapsed(!collapsed)}
            />
          ) : (
            <MenuFoldOutlined
              style={{ marginLeft: 16, cursor: "pointer" }}
              onClick={() => setCollapsed(!collapsed)}
            />
          )}
          {currentUser ? (
            <div className="navbar-nav ml-auto" style={{float: "right", marginRight: 36, display: "flex", flexDirection: "row"}}>
              <li className="nav-item">
                <a href="/login" className="logout" onClick={logOut}>
                  Đăng Xuất
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto" style={{float: "right", marginRight: 36 }}>
              <li className="nav-item">
                <Link to={"/login"} className="login">
                  Đăng Nhập
                </Link>
              </li>
            </div>
          )}
        </Header>
        <Content style={{ margin: "24px 16px", padding: 24, background: "#fff" }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
